<template>
  <v-container fluid fill-height>
    <v-layout justify-center align-center>
      <v-flex text-xs-center>
        <h1>Whoops, kan de pagina niet vinden..</h1>
        <router-link to="/">Terug naar home?</router-link>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {}
</script>

